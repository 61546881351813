import {
  GetAgencyId,
  GetRole,
  IsPrivileged,
  tryGetToken,
} from "@/utils/common/auth";
import { NextResponse } from "next/server";
import lscache from "lscache";
import { CACHE_KEYS } from "./cache";
import { UserRoles } from "@/enums/user";
import { StreamableValue } from "ai/rsc/dist";
import { CoreTool, StreamTextResult } from "ai";

interface ResponseError {
  response: {
    status: number;
    statusText: string;
  };
}

function instanceOfResponseError(o: any): o is ResponseError {
  if (!("response" in o)) {
    return false;
  }
  const response = o["response"];
  return "status" in response && "statusText" in response;
}

/**
 * A utility function for making API requests.
 * This utility pulls the access token from the incoming request, if available,
 * and passes it through to the callback function, `callback`.
 *
 * @param req The incoming request.
 * @param rolesAllowed The roles allowed to access the route. Empty is allowed for all roles.
 * @param callback A callback that takes in an accessToken
 *    and returns a `Promise<Response>`.
 * @returns A `Promise<Response>` that can be handled by a next.js route.
 */
export async function RequireAuth<T>(
  req: Request,
  rolesAllowed: UserRoles[],
  callback: (accessToken: string, agencyId: string) => Promise<Response | T>
) {
  const accessToken = tryGetToken(req);
  if (!accessToken) {
    return NextResponse.json({}, { status: 401 });
  }

  const role = GetRole(accessToken);

  if (rolesAllowed.length > 0 && !rolesAllowed.includes(role)) {
    return NextResponse.json({}, { status: 403 });
  }

  try {
    const agencyId = GetAgencyId(accessToken) ?? "";
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== "development" && agencyId) {
      console.info("Agency ID: ", agencyId);
    }

    return await callback(accessToken, agencyId);
  } catch (e) {
    if (instanceOfResponseError(e)) {
      console.error(
        "Error Processing Callback: status=(%s)",
        e.response.status
      );

      return NextResponse.json(
        {},
        { status: e.response.status, statusText: e.response.statusText }
      );
    }

    console.error("Unknown error", e);
    throw new Error("Error Processing Callback", { cause: e });
  }
}

/**
 * A utility function for making API requests for admins.
 * This utility pulls the access token from the incoming request, if available,
 * and passes it through to the callback function, `callback`.
 *
 * @param req The incoming request.
 * @param callback A callback that takes in an accessToken
 *    and returns a `Promise<Response>`.
 * @returns A `Promise<Response>` that can be handled by a next.js route.
 */
export async function RequireAdminAuth(
  req: Request,
  callback: (accessToken: string) => Promise<Response>
) {
  const accessToken = tryGetToken(req);
  if (!accessToken) {
    return NextResponse.json({}, { status: 401 });
  }

  if (!IsPrivileged(accessToken)) {
    return NextResponse.json({}, { status: 403 });
  }

  try {
    return await callback(accessToken);
  } catch (e) {
    if (instanceOfResponseError(e)) {
      console.error(
        "Error Processing Callback: status=(%s)",
        e.response.status
      );

      return NextResponse.json(
        {},
        { status: e.response.status, statusText: e.response.statusText }
      );
    }

    console.error("Unknown error", e);
    throw new Error("Error Processing Callback", { cause: e });
  }
}

export async function api<T_Request = null, T_Response = any>(
  accessToken: string | (() => Promise<string | undefined>),
  url: string,
  method: "GET" | "PUT" | "POST" | "DELETE" = "GET",
  body?: T_Request,
  revalidateSeconds = 0,
  doNotParse = false,
  fallback?: T_Response,
  silent?: boolean
): Promise<T_Response | null> {
  try {
    let token: string | undefined = "";
    if (typeof accessToken === "function") {
      token = await accessToken();
    } else {
      token = accessToken;
    }

    if (token === undefined) {
      console.error("No access token provided");
      return null;
    }

    const response = await fetch(url, {
      method: method,
      headers:
        token.length > 0
          ? {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }
          : {
              "Content-Type": "application/json",
            },
      body: body ? JSON.stringify(body) : undefined,
      next: { revalidate: revalidateSeconds },
    });

    if (!response.ok) {
      if (fallback) {
        return fallback;
      }

      if (!silent) {
        console.error(
          `Error ${method}ing data for ${url}`,
          await response.text()
        );
      }
      return null;
    }

    if (doNotParse) {
      return null;
    }

    return (await response.json()) as T_Response;
  } catch (e) {
    if (!silent) {
      console.error(`Error ${method}ing data | general failure`, e);
    }
    return null;
  }
}

export async function apiLive<T_Response = any>(
  accessToken: string | (() => Promise<string | undefined>),
  url: string,
  cacheKey?: CACHE_KEYS,
  cacheTtl?: number,
  fallback?: T_Response,
  silent?: boolean
): Promise<T_Response | null> {
  if (cacheKey) {
    const cached: null | T_Response = lscache.get(cacheKey);
    if (cached) {
      return cached;
    }
  }

  const response = await api<void, T_Response>(
    accessToken,
    url,
    "GET",
    undefined,
    0,
    false,
    fallback,
    silent
  );

  if (response && cacheKey) {
    lscache.set(cacheKey, response, cacheTtl);
  }

  return response;
}
